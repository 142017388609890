import React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { Content } from '../components/content';

export default function() {
  const title = 'Odontopediatría';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <Content>
        <p>
          Odontopediatría es la especialidad que se encarga de diagnosticar, atender y resolver los problemas
          odontológicos en niños y adolescentes. El odontopediatra será, por tanto, el encargado de explorar y tratar al
          paciente niño o adolescente. También se encarga de detectar posibles anomalías en la posición de los maxilares
          o dientes para remitir al ortodoncista y de hacer un tratamiento restaurador en caso de necesitarlo. El
          tratamiento restaurador principalmente se compone de tratar los traumatismos, usar selladores, que consiste en
          obturar levemente los surcos y fisuras de las piezas dentales para evitar posibles caries, y en tratar las
          caries producidas y sus consecuencias. Es muy importante destacar en edades tempranas el rol del
          Odontopediatra como agente de prevención. Esto se logra gracias a la aplicación de fluoruros, un buen
          asesoramiento dietario y la enseñanza de una correcta técnica de cepillado de acuerdo a la edad del paciente.
        </p>
        <p>La motivación y la enseñanza de buenos hábitos son el factor predisponente para lograr una boca en salud.</p>

        <h2>Preguntas frecuentes</h2>
        <h3>¿A qué edad aparecen los dientes de nuestros hijos?</h3>
        <p>
          Los dientes temporales son 20. De ellos 10 se instalan en el maxilar inferior y 10 en el maxilar superior.
          Estos dientes comienzan su proceso de formación durante el embarazo materno comenzando su aparición durante
          los 5 y 10 meses de vida, completando su desarrollo entre los 3 y 5 primeros años de vida. Estos primeros
          dientes son conocidos como dientes de leche. Aunque estos dientes sean transitorios el deterioro de los mismos
          y un tratamiento insuficiente repercutirán en sus sucesores los dientes permanentes y en la articulación y
          armonía dentaria. Es por ello que es de suma importancia que la madre se informe para tener una correcta
          higiene desde bebés e ir acompañando el desarrollo de su niño. Los dientes definitivos comienzan a aparecer a
          partir de los 5 o 6 años. En paralelo y hasta los 11 o 12 años comienzan a caerse los dientes de leche, dando
          espacio y paso a las piezas dentales definitivas, culminando el proceso de aparición de nuevas piezas cerca de
          los 20 años, momento en el que aparecen las muelas de juicio. Es de destacar que es muy importante el control
          de la cavidad bucal durante toda la infancia, particularmente durante el proceso de recambio ya que así podrán
          constatarse alteraciones en el proceso de mordida.
        </p>
        <h3>¿Qué trastornos ocurren con la salida de los primeros dientes en los niños?</h3>
        <p>
          Los niños realizan generalmente claras manifestaciones y demostraciones con la aparición de sus primeros
          dientes. Algunas evidencias de la nueva dentición son: pérdida de apetito, intranquilidad, irritabilidad,
          sueño ligero, etc. También tienen la propensión a introducirse en la boca objetos de cierta dureza y rigidez.
          Consultado el especialista los padres pueden atenuar las molestias aplicando un analgésico local y permitir
          que el niño utilice mordillos.
        </p>
        <h3>¿Para qué sirve el flúor?</h3>
        <p>
          El flúor genera una protección sobre los dientes ante la acción de la placa bacteriana. Los hace más
          resistentes ante el ataque de los ácidos producidos por ella. Es decir, que el flúor como agente preventivo y
          de tratamiento es de suma importancia para evitar el deterioro de las piezas dentales.
        </p>
      </Content>
    </Layout>
  );
}
